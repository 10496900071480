<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="fa fa-credit-card" aria-hidden="true"></i
        >{{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
        <el-form ref="form" :model="form" auto-complete="nope">
          <div class="row">
            <div class="col-md-24">
              <h2 class="credit-username">
                <label><i class="flaticon2-user"></i></label>
                {{ user_name }}
                <span style="font-size: 18px"
                  >(<b class="active"
                    >Total Credit : $
                    {{
                      credit.approved_total_credit
                        ? parseFloat(credit.approved_total_credit).toFixed(2)
                        : "0.00"
                    }}</b
                  >)</span
                >
              </h2>
              <h5 class="credit-username">
                <label><i class="flaticon-suitcase"></i></label>
                <span> Credit Term </span>
                <span>
                  (<b class="active">{{
                    credit_term ? credit_term.term_name + " days" : "Nil"
                  }}</b
                  >)</span
                >
                <div  v-if="credit.status == 'Pending'">
                <el-tag size="mini" style="margin-left: 10px">
                  <a href="#" @click.prevent="ChangeTerm()">Change</a>
                </el-tag>
                </div>

              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <template>
                <div class="credit-container">
                  <el-divider content-position="left"
                    >Request Credit</el-divider
                  >
                  <h2 class="text-center">
                    ${{
                      credit.credit_limit
                        ? parseFloat(credit.credit_limit).toFixed(2)
                        : 0.0
                    }}
                  </h2>
                </div>
              </template>
            </div>
            <div class="col-sm-4">
              <template>
                <div class="credit-container">
                  <el-divider content-position="left"
                    >Available Credit</el-divider
                  >
                  <h2 class="text-center">
                    ${{
                      credit.available_credit
                        ? parseFloat(credit.available_credit).toFixed(2)
                        : 0.0
                    }}
                  </h2>
                </div>
              </template>
            </div>
            <div class="col-sm-4">
              <template>
                <div class="credit-container">
                  <el-divider content-position="left"
                    >Balance Credit</el-divider
                  >
                  <h2 class="text-center">
                    ${{
                      credit.balance_showing
                        ? parseFloat(credit.balance_showing).toFixed(2)
                        : 0.0
                    }}
                  </h2>
                </div>
              </template>
            </div>
          </div>
          <div class="row mt-6 mb-5" style="text_align: center">
            <div
              class="col-md-3 ml-5"
              v-for="(document, index) in upload_documents"
              :key="index"
            >
              <el-image
                style="width: 100%; height: 120px"
                :src="img_url+document.document_filename"
                :preview-src-list="img_url+document.document_filename"
              >
              </el-image>
              <div class="mt-3 document_name">
                {{ document.document_name }}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12" v-if="credit.status != 'Rejected'">
              <h5>
                <b>Verified Document</b
                ><el-tag size="mini" style="margin-left: 10px" v-if="credit.status == 'Pending'"
                  ><a href="#" @click.prevent="createNewRequiredDocument"
                    >Add Document</a
                  ></el-tag
                >
              </h5>
              <el-form-item prop="document_list">
                <el-checkbox-group
                  v-model="form.document_list"
                >
                  required_credit_documents
                  <el-checkbox
                    v-for="required_credit_document in required_credit_documents"
                    :key="required_credit_document.id"
                    :checked="
                      checkSelected(required_credit_document.document_name)
                    "
                    :label="required_credit_document.document_name"
                    :value="required_credit_document.id"
                    >{{ required_credit_document.document_name }}
                    <span
                      @click="
                        editVerifiedDoc(
                          required_credit_document.id,
                          required_credit_document.document_name
                        )
                      "
                      class="el-icon-edit active" v-if="credit.status == 'Pending'"
                    ></span>
                    <span
                      @click="
                        deleteVerifiedDoc(
                          required_credit_document.id,
                          required_credit_document.document_name
                        )
                      "
                      class="el-icon-delete red" v-if="credit.status == 'Pending'"
                    ></span
                  ></el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </div>
            <span class="form-err" v-if="form.errors.has('document_list')"
              >Verified Document is required</span
            >
          </div>
          <div class="row" v-if="credit.status == 'Rejected'">
            <div class="col-sm-3">
              <span style="font-size: 18px">Status : </span
              ><span style="font-size: 18px; color: red"><b>Rejected</b></span>
            </div>
          </div>
          <div class="row" v-if="credit.status == 'Rejected'">
            <div class="col-sm-12">
              <span style="font-size: 18px">Reject Reason : </span
              ><span
                ><b>{{ credit.reject_reason }}</b></span
              >
            </div>
          </div>
          <div class="row" v-if="credit.status == 'Rejected'">
            <div class="col-sm-3">
              <span style="font-size: 18px">Reject By : </span
              ><span
                ><b>{{ form.verified_by }}</b></span
              >
            </div>
          </div>
          <div class="row" v-if="credit.status != 'Rejected'">
            <div class="col-sm-3">
              <el-form-item label="Approved amount" prop="approved_credit">
                <el-input
                  prefix-icon="fas fa-dollar-sign"
                  v-model="form.approved_credit"
                  @change="checkAmount(form.approved_credit)"
                ></el-input>
              </el-form-item>
              <span
                class="form-err"
                v-if="form.errors.has('approved_credit')"
                >{{ form.errors.get("approved_credit") }}</span
              >
            </div>
            <div class="col-sm-3">
              <el-form-item label="Verified By" prop="verified_by">
                <el-input
                  v-model="form.verified_by"
                  @input.native="capitalize"
                ></el-input>
              </el-form-item>
              <span class="form-err" v-if="form.errors.has('verified_by')"
                >Verified field is required</span
              >
            </div>
          </div>
          <div v-if="credit.status != 'Rejected'" class="mt-9">
            <b-button
              v-if="credit.status != 'Approved'"
              class="mr-3"
              variant="primary"
              @click="updateCredit('Approved')"
              >Approve</b-button
            >
            <b-button
              v-if="credit.status != 'Approved'"
              variant="danger"
              @click="rejectCredit()"
              >Reject</b-button
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  getCreditView,
  getCreditRequiredlist,
  deleteCreditVerifiedDoc,
  getUploadDocuments
} from "@/api/customer";
import CreateDocument from "@/components/CreateCustomer/document_type";
import CreateRequiredDocument from "@/components/CreateCustomer/required_document";
import { globalEvents } from "@/utils/globalEvents";
import CreditTerm from "@/components/CreateCustomer/credit_term.vue";
import RejectCredit from "@/components/CreateCustomer/reject_credit.vue";
export default {
  props: {
    credit_id: {
      type: Number,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Customer Credit" };
      },
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      credit: [],
      user_name: null,
      img_url: "https://ctshub.s3.ca-central-1.amazonaws.com/credit/",
      url1: "",
      url2: "",
      url3: "",
      url4: "",
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList4: [],
      required_credit_documents: [],
      form: new Form({
        status: null,
        document_list: [],
        verified_by: null,
        approved_credit: null,
        customer_id: null,
        reject_reason: null,
      }),
      title_text: "Approve Credit",
      document_name: [],
      credit_term: [],
      upload_documents : []
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Credits", route: "/customer/credit" },
      { title: "Customer Credits" },
    ]);
    globalEvents.$on("RequiredDocument", (doc, id) => {
      if (doc && !id) {
        this.required_credit_documents.push(doc);
      }
      if (id && doc) {
        for (var reqdoc in this.required_credit_documents) {
          if (this.required_credit_documents[reqdoc].id == id)
            this.required_credit_documents[reqdoc].document_name =
              doc.document_name;
        }
      }
    });

    globalEvents.$on("creditTerm", (added) => {
      this.credit_term = [];
      this.credit_term = added.credit_term;
      console.log(added);
    });
    globalEvents.$on("CreditAdded", (added) => {
      if (added) {
        this.closeForm();
      }
    });
  },
  methods: {
    closeForm() {
      if (!this.form.submiting) {
        this.$emit("close");
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    getCreditview() {
      this.loading = true;
      getCreditView(this.credit_id).then((response) => {
        this.credit = response.data.data;
        this.credit_term = response.data.data.get_customer.credit_term;
        this.form.customer_id = response.data.data.customer_id;
        this.form.verified_by = response.data.data.verified_by;
        this.form.approved_credit =
          this.credit.approved_credit == 0
            ? "0.00"
            : this.credit.approved_credit;
        if (this.credit.get_customer.last_name) {
          this.user_name =
            this.credit.get_customer.first_name +
            " " +
            this.credit.get_customer.last_name;
        } else this.user_name = this.credit.get_customer.first_name;
        this.srcList1.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "credit/" +
            response.data.data.document_license
        );
        this.url1 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "credit/" +
          response.data.data.document_license;
        this.srcList2.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "credit/" +
            response.data.data.document_passport
        );
        this.url2 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "credit/" +
          response.data.data.document_passport;
        this.srcList3.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "credit/" +
            response.data.data.document_health
        );
        this.url3 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "credit/" +
          response.data.data.document_health;
        this.srcList4.push(
          process.env.VUE_APP_BASE_IMAGE_URL +
            "credit/" +
            response.data.data.document_other
        );
        this.url4 =
          process.env.VUE_APP_BASE_IMAGE_URL +
          "credit/" +
          response.data.data.document_other;
        this.form.refered_by = this.credit.refered_by;
        this.form.available_credit = this.credit.available_credit;
        if (response.data.data.document_name) {
          let document = response.data.data.document_name.split(",");
          for (var i = 0; i < document.length; i++) {
            if (document[i] == "Driving license") {
              this.document_name.push({
                name: document[i],
                image: this.url1,
                src: this.srcList1,
              });
            }
            if (document[i] == "Passport") {
              this.document_name.push({
                name: document[i],
                image: this.url2,
                src: this.srcList2,
              });
            }
            if (document[i] == "Health Card") {
              this.document_name.push({
                name: document[i],
                image: this.url3,
                src: this.srcList3,
              });
            }
            if (document[i] == "Others") {
              this.document_name.push({
                name: document[i],
                image: this.url4,
                src: this.srcList4,
              });
            }
          }
        }

        this.loading = false;
      });
    },
    checkAmount(amount) {
      this.form.errors.add("approved_credit", " ");
      let due_amount = parseFloat(amount)
        .toFixed(2)
        .match(/^-?[0-9]*[.][0-9]+$/);
      if (this.credit.credit_limit >= due_amount) {
        if (due_amount != null) {
          this.form.approved_credit = parseFloat(due_amount).toFixed(2);
        } else {
          this.form.approved_credit = "";
          this.form.errors.add("approved_credit", "Please Enter Amount Only");
        }
      } else {
        this.form.approved_credit = "";
        this.form.errors.add("approved_credit", "Please Enter Max Amount Only");
      }
    },
    editVerifiedDoc(id, name) {
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Edit",
            title: "Update Verified Document",
          },
          id: id,
          name: name,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    deleteVerifiedDoc(id, name) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete " + name + " ?", {
          title: "Please Confirm",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            deleteCreditVerifiedDoc(id).then((response) => {
              if (response.data.status) {
                for (var reqdoc in this.required_credit_documents) {
                  if (this.required_credit_documents[reqdoc].id == id)
                    this.required_credit_documents.splice(reqdoc, 1);
                }
                this.$showResponse("success", response.data.message);
              }
              this.loading = false;
            });
          } else {
            return false;
          }
        });
    },
    getCreditRequiredlist() {
      getCreditRequiredlist().then((response) => {
        this.required_credit_documents = response.data.data;
      });
    },
    getUploadDocuments(){
      getUploadDocuments(this.credit_id).then((response) => {
        this.upload_documents = response.data.data;
      });
    },
    CreateDocument() {
      this.$modal.show(
        CreateDocument,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    rejectCredit() {
      this.$modal.show(
        RejectCredit,
        {
          credit_id: this.credit_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    updateCredit(status) {
      this.loading = true;
      this.form.status = status;
      this.api_url = "customer/credit/" + this.credit_id;
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            globalEvents.$emit("CreditAdded", response.data);
            this.$emit("close");
            //this.$router.push({ path: "/customer/credit/" });
            this.loading = false;
            if (response.data.status == "Approved") {
              this.$showResponse("success", response.message);
            } else {
              this.$showResponse("success", "Rejected  Customer Credit");
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    createNewRequiredDocument() {
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Add",
            title: "Add Verified Document",
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    ChangeTerm() {
      this.$modal.show(
        CreditTerm,
        {
          customer_id: this.form.customer_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600",
        }
      );
    },
    checkSelected(name) {
      if (!this.credit.document_list) {
        return false;
      } else {
        var document_list = this.credit.document_list.split(",");
        if (document_list.includes(name)) return true;
        else return false;
      }
    },
  },
  created() {
    this.getCreditview();
    this.getCreditRequiredlist();
    this.getUploadDocuments();
  },
};
</script>

<style scoped>
.review-layout {
  border-style: groove;
  padding: 40px;
  border-color: #366092;
}
.el-icon-circle-close {
  font-size: 40px;
}

.document_name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
</style>
