<template>
<div>
  <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
  </div>
  <div class="create-brand">
    <div class="col-xl-12">
      <div class="row">
        <div class="col-xl-10">
          <div class="form-group">
            <input
              type="text"
              placeholder="New Document Name"
              v-model="form.document_name"
              @input.native="capitalize"
              class="form-control form-control-solid form-control-lg"
            />
          </div>
        </div>
        <div class="col-xl-2">
          <button
            type="button"
            class="
              btn btn-sm btn-primary
              custom-button-primary custom-addrem-btn
            "
            @click="createDocument()"
          >
            {{ label.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Form from "@/core/services/api.form.services";
import { globalEvents } from "@/utils/globalEvents";
export default {
  name: "add-brand",
  props: {
    id:{
      type: Number,
    },
    name: {
      type: String
    },
    label: {
      type: Object,
      default() {
        return { button: "Add", title: "Add Verified Document" };
      },
    },
  },
  data() {
    return {
      loading: false,
      form: new Form({
        id: null,
        document_name: null,
      }),

      api_url: "credit/require_document/create",
      response_msg: "Document created successfully.",
    };
  },
  components: {},
  methods: {
    createDocument: function () {
      this.loading = true;
      var id=this.id;
      this.form.post(this.api_url).then((response) => {
        globalEvents.$emit("RequiredDocument", response.data,id);
        this.$emit("close");

        if (response.status) {
          this.loading = false;
          this.$showResponse("success", response.message);
        }
      });
    },
    closeForm() {
      this.$emit('close')
    },
  },
  created(){
    if(this.name){
        this.form.document_name=this.name;
    }
    if(this.id)
      this.form.id=this.id;
  }
};
</script>
