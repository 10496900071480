<script>
import { getCustomerCredit } from "@/api/customer";
import CreditView from "@/components/CreateCustomer/view_credit.vue";
import AddCredit from "@/components/CreateCustomer/add_credit.vue";
import { globalEvents } from "@/utils/globalEvents";

export default {
  name: "CreditDetails",
  props: ["Credits"],

  data() {
    return {
      creditDetails: [],
      loading: false,
      customerId: parseInt(this.$route.params.customer_id),
    };
  },

  created() {
    this.getCreditsDetails();
  },

  mounted() {
    globalEvents.$on("CreditAdded", (credit) => {
      if (credit) {
        this.getCreditsDetails();
      }
    });
  },

  methods: {
    getCreditsDetails() {
      this.loading = true;
      getCustomerCredit(this.customerId).then((response) => {
        this.creditDetails = response.data;
        this.loading = false;
      });
    },

    handleActionsCredit(evt, row) {
      if (evt === 1) {
        this.$modal.show(
          CreditView,
          { credit_id: row.id },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: true,
            adaptive: true,
            width: "800px",
          }
        );
      }
    },

    CreateCredits() {
      this.$modal.show(
        AddCredit,
        { customer_id: this.customerId },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "800",
        }
      );
    },
  },
};
</script>
<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <el-button type="primary" @click.prevent="CreateCredits"
          >Add Credit</el-button
        >
      </div>
      <div class="font-weight-bolder">
        <i class="flaticon-suitcase" style="color: #3699ff"></i> Credit Limits :
        ${{
          creditDetails.data
            ? creditDetails.data.approved_total_credit.toLocaleString("en-US")
            : "0.00"
        }}
      </div>
      <div class="font-weight-bolder">
        <i class="flaticon-suitcase" style="color: green"></i> Available Credit
        : ${{
          creditDetails.data
            ? creditDetails.data.available_credit.toLocaleString("en-US")
            : "0.00"
        }}
      </div>
      <div class="font-weight-bolder">
        <i class="flaticon-suitcase" style="color: red"></i> Balance Due: ${{
          creditDetails.data
            ? creditDetails.data.balance_showing.toLocaleString("en-US")
            : "0.00"
        }}
      </div>
    </div>
    <div class="mt-5">
      <el-table
        :data="creditDetails.list"
        style="width: 100%"
        border
        element-loading-text="Loading"
        v-loading="loading"
      >
        <el-table-column prop="created_at" label="Created Date" width="120">
          <template slot-scope="scope">
            <span slot="reference">
              {{ scope.row.created_at | formatDate("DD MMM YYYY") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="approved_total_credit"
          label="Credit Limit"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span>
              $ {{ scope.row.approved_total_credit.toLocaleString("en-US") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="credit_limit"
          label="Request Credit"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span>
              $ {{ scope.row.credit_limit.toLocaleString("en-US") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="credit_limit"
          label="Approve Credit"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <span>
              $ {{ scope.row.approved_credit.toLocaleString("en-US") }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="available_credit"
          label="Available Credit"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            $ {{ scope.row.available_credit.toLocaleString("en-US") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="balance_showing"
          label="Balance Due"
          align="center"
          width="120"
        >
          <template slot-scope="scope" style="color: red">
            $ {{ scope.row.balance_showing.toLocaleString("en-US") }}
          </template>
        </el-table-column>

        <el-table-column prop="status" label="Status" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 'Approved'">
              <b>{{ scope.row.status }}</b>
              <span v-if="scope.row.record_status == 1">
                <i class="flaticon2-correct text-success font-size-h5"></i>
              </span>
            </span>
            <span v-else class="red">
              <b>{{ scope.row.status }}</b>
            </span>
          </template>
        </el-table-column>

        <el-table-column
          prop="verified_by"
          label="Approved/Rejected by"
          :min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.verified_by }}
          </template>
        </el-table-column>

        <el-table-column
          prop="document_name"
          label="Reject reason"
          :min-width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.reject_reason }}
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          align="center"
          label="Actions"
          width="80"
        >
          <template slot-scope="scope">
            <el-dropdown
              size="medium"
              @command="handleActionsCredit($event, scope.row)"
            >
              <i class="flaticon2-menu-1"></i>
              <el-dropdown-menu slot="dropdown" class="action-dropdown">
                <el-dropdown-item :command="1">
                  <span v-if="scope.row.status == 'Pending'"
                    ><i class="fa fa-pen"></i>Edit</span
                  >
                  <span v-else><i class="fa fa-eye"></i>view</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<style lang="scss" scoped>
</style>