<template>
    <div>
      <div slot="header" class="modal-header popup-header">
        <span class="modal-title-popup"><i class="fa fa-credit-card" aria-hidden="true"></i>{{ label.title }}</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
      </div>
       <div class="card card-custom" v-loading="loading">
            <div class="card-body p-0">
                  <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="row">
                                            <div class="col-xl-12">
                                               <el-form-item label="Reject reason" prop="reject_reason">
                                                 <el-input type="textarea" :rows="2" v-model="form.reject_reason" ></el-input>
                                                 <span class="form-err" v-if="form.errors.has('reject_reason')">Reject reason field is required</span>
                                              </el-form-item>                                                   
                                            </div>

                                          <div class="col-xl-12">
                                             <el-form-item label="Reject by" prop="reject_by">
                                                 <el-input v-model="form.verified_by" ></el-input> 
                                                 <span class="form-err" v-if="form.errors.has('verified_by')">Reject by field is required</span>                                                
                                              </el-form-item>                                                 
                                            </div>
                                           
                                        </div>
                                    </div>                                   
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-5">
                                <a @click="updateCredit('Rejected')" class="text-white">
                                    <div class="btn btn-primary btn-sm font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
                  <el-dialog
                    title="Credit Reject Confirmation"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose">
                    <span><b>Cancel Reason</b></span>
                    <el-input type="textarea" minRows="2" v-model="form.reject_reason"></el-input>
                    <span class="form-err" v-if="form.errors.has('reject_reason')">Reject reason field is required</span>
                        <span class="mt-5"><b>Reject by</b></span>
                        <el-input v-model="form.verified_by" style="with:50%"></el-input> 
                        <span class="form-err" v-if="form.errors.has('verified_by')">Reject by field is required</span> 
                    <span slot="footer" class="dialog-footer">
                        <el-button type="warning" @click="dialogVisible = false">Cancel</el-button>
                        <el-button type="primary" @click="updateCredit('Rejected')">Confirm</el-button>
                    </span>
                    </el-dialog>
            </div>
       </div>
  
    </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import { getCreditView, getCreditRequiredlist, deleteCreditVerifiedDoc } from "@/api/customer";
import CreateDocument from "@/components/CreateCustomer/document_type";
import CreateRequiredDocument from "@/components/CreateCustomer/required_document";
import { globalEvents } from "@/utils/globalEvents";
import CreditTerm from "@/components/CreateCustomer/credit_term.vue";
export default {
   props: {
      credit_id: {
      type: Number,
    },
    label: {
      type: Object,
      default() {
        return { button: "Reject", title: "Credit Reject" };
      },
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      credit: [],
      user_name: null,
      img_url: "https://stagingurl.tk/ctshub_api/public/images/credit/",
      url1: "",
      url2: "",
      url3: "",
      url4: "",
      srcList1: [],
      srcList2: [],
      srcList3: [],
      srcList4: [],
      required_credit_documents: [],
      form: new Form({
        status: null,
        document_list: [],
        verified_by: null,
        approved_credit:null,
        customer_id : null,
        reject_reason:null,
      }),
      title_text: "Approve Credit",
      document_name: [],
      credit_term : [],
      submit_btn_text:'Reject'
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Credits", route: "/customer/credit" },
      { title: "Customer Credits" },
    ]);
    globalEvents.$on("RequiredDocument", (doc,id) => {
      if (doc && !id) {
        this.required_credit_documents.push(doc);
      }
      if(id && doc){
        for(var reqdoc in this.required_credit_documents){
          if(this.required_credit_documents[reqdoc].id==id)
              this.required_credit_documents[reqdoc].document_name=doc.document_name;
        }
      }
    });

     globalEvents.$on("creditTerm", (added) => {    
      this.credit_term = [];
      this.credit_term = added.credit_term;
     console.log(added);
    });
  },
  methods: {
      closeForm() {
      if (!this.form.submiting) {
        this.$emit("close");
      }
    },
    handleClose(){
      this.dialogVisible = false
    },
    getCreditview() {
      this.loading = true;
      getCreditView(this.credit_id).then((response) => {
        console.log(response);
        this.credit = response.data.data;
        this.credit_term = response.data.data.get_customer.credit_term;
        this.form.customer_id = response.data.data.customer_id;
        this.form.verified_by = response.data.data.verified_by;
        this.form.approved_credit = this.credit.approved_credit== 0?'0.00':this.credit.approved_credit;
        if (this.credit.get_customer.last_name) {
          this.user_name =
            this.credit.get_customer.first_name +
            " " +
            this.credit.get_customer.last_name;
        } else this.user_name = this.credit.get_customer.first_name;
        this.srcList1.push(process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_license);
        this.url1 = process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_license;
        this.srcList2.push(process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_passport);
        this.url2 = process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_passport;
        this.srcList3.push(process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_health);
        this.url3 = process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_health;
        this.srcList4.push(process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_other);
        this.url4 = process.env.VUE_APP_BASE_IMAGE_URL +'/credit/' + response.data.data.document_other;
        this.form.refered_by = this.credit.refered_by;
        this.form.available_credit = this.credit.available_credit;
        if (response.data.data.document_name) {
          let document = response.data.data.document_name.split(",");
          for (var i = 0; i < document.length; i++) {
            if (document[i] == "Driving license") {
              this.document_name.push({
                name: document[i],
                image: this.url1,
                src: this.srcList1,
              });
            }
            if (document[i] == "Passport") {
              this.document_name.push({
                name: document[i],
                image: this.url2,
                src: this.srcList2,
              });
            }
            if (document[i] == "Health Card") {
              this.document_name.push({
                name: document[i],
                image: this.url3,
                src: this.srcList3,
              });
            }
            if (document[i] == "Others") {
              this.document_name.push({
                name: document[i],
                image: this.url4,
                src: this.srcList4,
              });
            }
          }
        }

        this.loading = false;
      });
    },
     checkAmount(amount){ 
            
      this.form.errors.add("approved_credit", " ");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
      if(this.credit.credit_limit >= due_amount){
         if(due_amount != null){
          this.form.approved_credit = parseFloat(due_amount).toFixed(2);
        }else{
          this.form.approved_credit = ''
          this.form.errors.add("approved_credit", "Please Enter Amount Only");
        } 

      }else{
          this.form.approved_credit = ''
          this.form.errors.add("approved_credit", "Please Enter Max Amount Only");

      }   
    },
    editVerifiedDoc(id,name){
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Edit",
            title: "Update Verified Document"
          },
          id:id,
          name:name
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    deleteVerifiedDoc(id,name){
      this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete "+name+" ?", {
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteCreditVerifiedDoc(id).then((response) => {
                if (response.data.status) {
                  for(var reqdoc in this.required_credit_documents){
                    if(this.required_credit_documents[reqdoc].id==id)
                        this.required_credit_documents.splice(reqdoc, 1);
                  }
                  this.$showResponse("success", response.data.message);
                }
                this.loading = false;
              });
            } else {
              return false;
            }
          });
    },
    getCreditRequiredlist() {
      getCreditRequiredlist().then((response) => {
        this.required_credit_documents = response.data.data;
      });
    },
    CreateDocument() {
      this.$modal.show(
        CreateDocument,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    rejectCredit(){
      this.dialogVisible = true;
    },
    updateCredit(status) {
      this.loading = true;
      this.form.status = status;
      this.api_url = "customer/credit/" + this.credit_id;
      this.form.post(this.api_url).then((response) => {
        if (response.status) {
          globalEvents.$emit('CreditAdded', response.data)
          this.$emit("close");
          //this.$router.push({ path: "/customer/credit/" });
          this.loading = false;
          if (response.data.status == "Approved") {
            this.$showResponse("success", response.message);
          } else {
            this.$showResponse("success", "Rejected  Customer Credit");
          }
        }
      }) .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    createNewRequiredDocument() {
      this.$modal.show(
        CreateRequiredDocument,
        {
          label: {
            button: "Add",
            title: "Add Verified Document"
          }
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    ChangeTerm() {
      this.$modal.show(
        CreditTerm,
        {
          customer_id: this.form.customer_id,
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600",
        }
      );
    },
    checkSelected(name) {
      if (!this.credit.document_list) return false;
      else {
        var document_list = this.credit.document_list.split(",");
        if (document_list.includes(name)) return true;
        else return false;
      }
    },
  },
  created() {
    this.getCreditview();
    this.getCreditRequiredlist();
  },
};
</script>