<template>   
<div >
    <div slot="header" class="modal-header popup-header">
          <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
      </div>
      <div class="card card-custom" v-loading="loading">
        <div class="card-body  p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false" style="padding:0px !important">  
                          <el-row>                             
                             <el-col :span="10">
                              <el-form-item label="Request credit terms" prop="term_id">                             
                              <el-select
                                v-model="form.term_id"
                                placeholder="Select"
                                clearable                                
                              >
                                <el-option class="add-container" value="">
                                  <a class="add-drop-btn" @click="CreateTerm()">
                                    <i class="el-icon-circle-plus"></i> Add Term
                                  </a>
                                </el-option>
                                <el-option
                                  v-for="(term, index) in term_list"
                                  :key="index"
                                  :label="term.term_name+' days'"
                                  :value="term.id"
                                  auto-complete="off"
                                >
                                </el-option>
                              </el-select>
                              <span
                                class="form-err"
                                v-if="form.errors.has('term_id')"
                                >{{ form.errors.get("term_id") }}</span
                              >
                            </el-form-item>
                             </el-col>
                              <el-col :span="24">
                                <div class="row">
                                  <div class="col-sm-12 ">
                                    <button class="btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="addType()">{{btn_text}}</button>                
                                  </div>
                                </div>  
                              </el-col>

                          </el-row>
                           
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import { getOrderTerm } from "@/api/order";
import CreateTerm from "@/components/CreateOrderTerm";
export default {
    name: 'add-type',
  props: {
       customer_id: {
            type: Number,
        },    
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Change Credit Term'}
	      }
	    }
  },
    data() {
    return {
        loading:false,
        error: null,
        btn_text : 'Save',
        form : new Form({      
        term_id:null,  
        customer_id : null,     
           
      }), 
      term_list: [],

    }
    },
     mounted() {
    this.form.customer_id = this.customer_id; 
    globalEvents.$on("TermAdded", (term) => {
      if (term) {
        this.term_list.push(term);
        this.form.term_id = term.term_name;
      }
    });  
    },
    methods: {
     closeForm() {
     this.$emit('close')
	     
        },
        getTermlist() {
            getOrderTerm().then((response) => {
                this.term_list = response.data.data;
            });
        },
         CreateTerm() {
      this.$modal.show(
        CreateTerm,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
         addType: function() {               
     
            this.api_url = 'change/credit/term'
            this.form.post(this.api_url).then((response) => {
              if(response.status){
                  globalEvents.$emit('creditTerm', response.data)
                  this.$emit('close')                  
                  this.loading=false;
                  this.$showResponse('success', response.message);
              }
            })
          }, 
    },
    created(){
        this.getTermlist();
    }

}
</script>